import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/public/welcome', pathMatch: 'full' },
  { 
    path:'public', 
    loadChildren: ()=> import('./pages/public-routes/public-routes.module')
      .then(mod => mod.PublicRoutesModule) 
  },
  { 
    path:'private', 
    loadChildren: ()=> import('./pages/private-routes/private-routes.module')
      .then(mod => mod.PrivateRoutesModule) 
  },
  // en caso que no encuentre la ruta se redirige a la página de inicio
  { path: '**', redirectTo: '/public/welcome' }, 
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
