import { NgModule,CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { AuthModule } from './auth/auth.module';
import {  AuthTmsRestUrl, ConsoleRest } from './util/constants';
import { AUTH_TMS_URL, AUTH_URL, CONSOLE_REST_URL} from './auth/app.oauth-tokens';
import { UnauthorizedInterceptor } from './auth/unauthorized.interceptor';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localeEs from '@angular/common/locales/es';
import { ComponentsStateService } from './services/components-state.service';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { provideNgxMask } from 'ngx-mask';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

registerLocaleData(localeEs)

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({ declarations: [
        AppComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent], 
    imports: [BrowserModule,
        RouterModule, 
        AppRoutingModule,
        BrowserAnimationsModule,
        NgxSpinnerModule,
        FontAwesomeModule,
        MatDialogModule,
        ToastrModule.forRoot(),
        AuthModule.forRoot(),
        NgIdleKeepaliveModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })], providers: [
        { provide: LOCALE_ID, useValue: 'es' },
        { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } },
        { provide: AUTH_URL, useValue: AuthTmsRestUrl },
        { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
        {
            provide: HTTP_INTERCEPTORS,
            useFactory: function (router: Router, authService: AuthService, componentsState: ComponentsStateService) {
                return new UnauthorizedInterceptor(router, authService, componentsState);
            },
            multi: true,
            deps: [Router, AuthService, ComponentsStateService]
        },
        { provide: CONSOLE_REST_URL, useValue: ConsoleRest },
        { provide: AUTH_TMS_URL, useValue: AuthTmsRestUrl },
        provideNgxMask(),
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fab);
  }
}
