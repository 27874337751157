import { Component ,ElementRef, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Chart, registerables } from 'chart.js';
import { AuthService } from './auth/auth.service';
import { Observable, Subscription } from 'rxjs';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { LanguageService } from './services/language.service';
import { RolHandlerService } from './services/rol-handler.service';
import { IconRegistryService } from './services/icon-registry.service';
import { ComponentsStateService } from './services/components-state.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: false
})
export class AppComponent implements OnInit {
  typeSelected: string;

  isAuthenticated$: Observable<boolean>;
  isDoneLoading$: Observable<boolean>;
  canActivateProtectedRoutes$: Observable<boolean>;
  
  title = 'MDM';//'admindashboard';

  onIdleEnd?:Subscription;
  onTimeout?:Subscription;
  onIdleStart?:Subscription;
  onTimeoutWarning?:Subscription;
  
  
  idleState = 'Not started.';
  timedOut = false;
  lastPing ?: Date = undefined;

  constructor(
    private readonly elementRef: ElementRef,  
    private readonly _router: Router,
    public dialog: MatDialog,
    private readonly idle: Idle, 
    private readonly keepalive: Keepalive,
    private readonly authService: AuthService,
    private readonly languageService: LanguageService,
    private readonly icons:IconRegistryService,
    private readonly rol:RolHandlerService,
    private readonly componentsHandler:ComponentsStateService
   ) {
    this.icons.load();
    this.languageService.init();
    this.typeSelected = 'ball-spin';
    this.isAuthenticated$ = this.authService.isAuthenticated$;
    this.isDoneLoading$ = this.authService.isDoneLoading$;
    this.canActivateProtectedRoutes$ = this.authService.canActivateProtectedRoutes$;

    Chart.register(...registerables); 
  }

  
  async ngOnInit() {
    let s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "./assets/js/main.js";
    this.elementRef.nativeElement.appendChild(s);

    this.isAuthenticated$.subscribe((validAuth:boolean)=>{
      if(validAuth) {
        console.debug("redirect to monitoring, is authenticated");
        this.configureIdleSessionTimeouts(this.idle, this.keepalive);
        const pathToNavigate = this.rol.getPathByPermissions();        
        this._router.navigateByUrl(pathToNavigate);
      } else {
        console.debug("not authenticated, redirect to public");
        this.idle.stop();
      }
    });
  }

  private configureIdleSessionTimeouts(idle: Idle, keepalive: Keepalive) {
    if(idle.isRunning()) return;
    
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(60);//60
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(15*60); // 15*60 - 15 minutos
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.onIdleEnd?.unsubscribe();
    this.onTimeout?.unsubscribe();
    this.onIdleStart?.unsubscribe();
    this.onTimeoutWarning?.unsubscribe();

    this.onIdleEnd = idle.onIdleEnd.subscribe(() => { 
      this.idleState = 'No longer idle.'
      console.debug(this.idleState);
      this.reset();
    });
    
    this.onTimeout = idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;

      console.debug(this.idleState);
      this.fullLogout();
      
    });
    
    this.onIdleStart = idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!'
        console.debug(this.idleState);
    });
    
    this.onTimeoutWarning = idle.onTimeoutWarning.subscribe((countdown) => {
      if(countdown < 15) {
        this.idleState = 'You will time out in ' + countdown + ' seconds!'
        console.debug(this.idleState);
      }
    });
    
    // sets the ping interval to 15 seconds
    keepalive.interval(15);
    keepalive.onPing.subscribe(() => this.lastPing = new Date());
    this.reset();
  }

  private fullLogout() { 
    this.authService.logoutAndClear();
    this.componentsHandler.clearData();
    this._router.navigateByUrl("/public/login");
  }

  private reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

}
