import { ModuleWithProviders, NgModule, Optional, SkipSelf, inject, provideAppInitializer } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './auth-guard.service';
import { LeaveComponentGuard } from './LeaveComponentGuard';
import { CustomPreloadingStrategy } from './custom-preloading.strategy';

import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';

import { authSpaConfig } from './auth-spa.config';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthService } from './auth.service';
import { authAppInitializerFactory } from './auth-app-initializer.factory';
import { authModuleConfig } from './auth-module-config';
import { AuthGuardWithForcedLogin } from './auth-guard-with-forced-login.service';
import { UnauthorizedInterceptor } from './unauthorized.interceptor';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return sessionStorage;
}

@NgModule({ declarations: [], imports: [CommonModule,
        OAuthModule.forRoot()], providers: [
        AuthService,
        AuthGuard,
        UnauthorizedInterceptor,
        AuthGuardWithForcedLogin,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      providers: [
        provideAppInitializer(() => {
        const initializerFn = (authAppInitializerFactory)(inject(AuthService));
        return initializerFn();
      }),
        { provide: AuthConfig, useValue: authSpaConfig },
        { provide: OAuthModuleConfig, useValue: authModuleConfig },
        { provide: OAuthStorage, useFactory: storageFactory },
        AuthService, UnauthorizedInterceptor, AuthGuard, LeaveComponentGuard, CustomPreloadingStrategy
      ],
      ngModule: AuthModule,
    };
  }
  
  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error('AuthModule is already loaded. Import it in the AppModule only');
    }
  }




 }
